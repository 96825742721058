import React from 'react';
import { useAuth } from '@veripass/react-sdk';

function SidebarAdmin() {
  const { user } = useAuth();

  return (
    <div className="left-side-menu">
      <div className="h-100" data-simplebar>
        {/* <!--- Sidemenu --> */}
        <div id="sidebar-menu">
          <ul id="side-menu">
            <li className="menu-title">Navigation</li>

            <li>
              <a href="/admin/">
                <i className="mdi mdi-view-dashboard-outline"></i>
                <span> Dashboard </span>
              </a>
            </li>

            <li className="menu-title mt-2">Quick links</li>

            <li>
              <a href="/admin/item/management/daily#add-gemstone">
                <i className="mdi mdi-plus"></i>
                <span> Add gem </span>
              </a>
            </li>

            <li className="menu-title mt-2">Management</li>

            
              <li>
                <a href="/admin/inventory/management">
                  <i className="mdi mdi-warehouse"></i>
                  <span> Inventories </span>
                </a>
              </li>
            

            {/* {user?.payload?.roles?.some((role) => role.slug === 'etruneid-admin') && ( */}
              <li>
                <a href="/admin/item/management/">
                  <i className="mdi mdi-package-variant-closed"></i>
                  <span> Items</span>
                </a>
              </li>
            {/* )} */}

            <li>
              <a href="/admin/item/management/daily">
                <i className="mdi mdi-package-variant-closed"></i>
                <span> Items Daily </span>
              </a>
            </li>

            {user?.payload?.roles?.some((role) => role.slug === 'etruneid-admin') && (
              <li>
                <a href="/admin/insight">
                  <i className="mdi mdi-chart-line"></i>
                  <span> Insights </span>
                </a>
              </li>
            )}

            {user?.payload?.roles?.some((role) => role.slug === 'etruneid-admin') && (
              <li>
                <a href="/admin/insight">
                  <i className="mdi mdi-cog"></i>
                  <span> Settings </span>
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default SidebarAdmin;

import React, { useEffect, useState, useRef } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '@veripass/react-sdk';

import {
  TextField,
  FormHelperText,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useSnackbar } from '@link-loom/react-sdk';
import { InventoryItemService } from '@services';

async function createEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.create(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
    min-width: ${(props) => (props.$isPopup ? '95vw' : '100%')};
  }
`;

const gemstoneNames = [
  { name: 'Amethyst', color: 'Purple' },
  { name: 'Garnet', color: 'Red' },
  { name: 'Aquamarine', color: 'Blue' },
  { name: 'Topaz', color: 'Blue' },
  { name: 'Peridot', color: 'Green' },
  { name: 'Smoky quartz', color: 'Brown' },
  { name: 'Opal', color: 'White' },
  { name: 'Tourmaline', color: 'Green' },
  { name: 'Morganite', color: 'Pink' },
  { name: 'Agate', color: 'Orange' },
  { name: 'Citrine', color: 'Orange' },
  { name: 'Quartz', color: 'White' },
  { name: 'Moonstone', color: 'White' },
  { name: 'Labradorite', color: 'Blue' },
  { name: 'Tanzanite', color: 'Purple' },
  { name: 'Iolite', color: 'Purple' },
  { name: 'Rhodochrosite', color: 'Pink' },
  { name: 'Cianite', color: 'Blue' },
  { name: 'Emerald', color: 'Green' },
  { name: 'Ruby', color: 'Red' },
  { name: 'Sapphire', color: 'Blue' },
  { name: 'Diamond', color: 'White' },
  { name: 'Pearl', color: 'White' },
  { name: 'Jade', color: 'Green' },
  { name: 'Coral', color: 'Pink' },
  { name: 'Hematite', color: 'Gray' },
  { name: 'Larimar', color: 'Blue' },
  { name: 'Sultanite', color: 'Brown' },
  { name: 'Andalusite', color: 'Brown' },
  { name: 'Beryl', color: 'Green' },
  { name: 'Lapis lazuli', color: 'Blue' },
  { name: "Tiger's eye", color: 'Brown' },
  { name: 'Sodalite', color: 'Blue' },
  { name: 'Malachite', color: 'Green' },
  { name: 'Rutilated Quartz', color: 'Yellow' },
  { name: 'Dendritic Quartz', color: 'White' },
  { name: 'Moissanite', color: 'White' },
  { name: 'Milky Quartz', color: 'White' },
  { name: 'Mother of pearl', color: 'White' },
  { name: 'Howlite', color: 'White' },
  { name: 'Amazonite', color: 'Blue' },
  { name: 'Jasper', color: 'Red' },
  { name: 'Intaglio', color: 'Red' },
  { name: 'Red ganju seeds', color: 'Red' },
  { name: 'Snowflake obsidian', color: 'Black' },
  { name: 'Onyx', color: 'Black' },
  { name: 'Fluorite', color: 'Purple' },
  { name: 'Zircon', color: 'White' },
  { name: 'Spinel', color: 'Blue' },
  { name: 'Alexandrite', color: 'Green' },
  { name: 'Rutile', color: 'Red' },
  { name: 'Ametrine', color: 'Purple' },
  { name: 'Heliodor', color: 'Yellow' },
  { name: 'Cubic Zirconia', color: 'White' },
  { name: 'Chrysocolla', color: 'Blue' },
  { name: 'Aventurine', color: 'Green' },
  { name: 'Diopside', color: 'Green' },
  { name: 'Turquoise', color: 'Blue' },
  { name: 'Crystal', color: 'White' },
  { name: 'Chalcedony', color: 'White' },
  { name: 'Amber', color: 'Orange' },
  { name: 'Nacre', color: 'White' },
  { name: 'Sphene', color: 'Green' },
  { name: 'Bixbite', color: 'Red' },
];

const gemstoneCutNames = [
  { name: 'Round', shape: 'Round', style: 'Brilliant' },
  { name: 'Oval', shape: 'Oval', style: 'Brilliant' },
  { name: 'Princess', shape: 'Square', style: 'Brilliant' },
  { name: 'Cushion', shape: 'Square', style: 'Modified Brilliant' },
  { name: 'Emerald', shape: 'Rectangular', style: 'Step' },
  { name: 'Asscher', shape: 'Square', style: 'Step' },
  { name: 'Marquise', shape: 'Navette', style: 'Brilliant' },
  { name: 'Radiant', shape: 'Rectangular', style: 'Brilliant' },
  { name: 'Pear', shape: 'Teardrop', style: 'Brilliant' },
  { name: 'Heart', shape: 'Heart', style: 'Brilliant' },
  { name: 'Straight Baguette', shape: 'Rectangular', style: 'Step' },
  { name: 'Kite', shape: 'Kite', style: 'Faceted' },
  { name: 'Lozenge', shape: 'Rhombus', style: 'Faceted' },
  { name: 'Trillion', shape: 'Triangular', style: 'Brilliant' },
  { name: 'Hexagonal', shape: 'Hexagonal', style: 'Step' },
  { name: 'Octagonal', shape: 'Octagonal', style: 'Step' },
  { name: 'Shield', shape: 'Shield', style: 'Faceted' },
  { name: 'Bullet', shape: 'Bullet', style: 'Faceted' },
  { name: 'Briolette', shape: 'Droplet', style: 'Faceted' },
  { name: 'Cabochon', shape: 'Oval', style: 'Polished' },
  { name: 'Checkerboard', shape: 'Square', style: 'Checkerboard' },
  { name: 'Tapered Baguette', shape: 'Tapered', style: 'Step' },
  { name: 'Half Moon', shape: 'Half Moon', style: 'Faceted' },
  { name: 'Fancy', shape: 'Geometric', style: 'Faceted' },
  { name: 'Sphere', shape: 'Round', style: 'Polished drilled' },
  { name: 'Half-Sphere', shape: 'Round', style: 'Polished drilled' },
  { name: 'Free', shape: 'Free', style: 'Carved' },
  { name: 'Rough', shape: 'Free', style: 'Unpolished' },
  { name: 'Trapezoid', shape: 'Trapezoid', style: 'Faceted' },
  { name: "Calf's Head", shape: 'Geometric', style: 'Step' },
  { name: 'Triangle', shape: 'Triangle', style: 'Step' },
  { name: 'Keystone', shape: 'Tapered', style: 'Step' },
  { name: 'Long Hexagonal', shape: 'Hexagonal', style: 'Step' },
  { name: 'Pentagonal', shape: 'Pentagonal', style: 'Step' },
  { name: 'Epaulette', shape: 'Shield', style: 'Step' },
  { name: 'Tapered Pentagonal', shape: 'Pentagonal', style: 'Step' },
  { name: 'Cut-Cornet Triangle', shape: 'Geometric', style: 'Step' },
  { name: 'Long Octagonal', shape: 'Octagonal', style: 'Step' },
  { name: 'Whistle', shape: 'Geometric', style: 'Step' },
  { name: 'Rhomboid', shape: 'Geometric', style: 'Step' },
  { name: 'Fan-Shape', shape: 'Circular Sector', style: 'Step' },
  { name: 'Window', shape: 'Geometric', style: 'Step' },
  { name: 'Double terminated point', shape: 'Geometric', style: 'Faceted' },
  { name: 'Obelisk', shape: 'Geometric', style: 'Faceted' },
  { name: 'Intaglio', shape: 'Free', style: 'Engraved' },
  { name: 'Cameo', shape: 'Free', style: 'Engraved' },
  { name: 'Baroque', shape: 'Irregular', style: 'Natural drilled' },
  { name: 'Cylinder', shape: 'Round', style: 'Polished drilled' },
  { name: 'Tumbled', shape: 'Irregular', style: 'Polished' },
  { name: 'Tile', shape: 'Rectangular', style: 'Polished' },
];

const gemstoneCutShapes = [
  'Round',
  'Oval',
  'Square',
  'Rectangular',
  'Navette',
  'Teardrop',
  'Heart',
  'Kite',
  'Rhombus',
  'Triangular',
  'Hexagonal',
  'Octagonal',
  'Shield',
  'Bullet',
  'Droplet',
  'Tapered',
  'Half Moon',
  'Geometric',
  'Free',
  'Trapezoid',
  'Triangle',
  'Pentagonal',
  'Circular Sector',
  'Irregular',
];

const gemstoneCutStyles = [
  'Brilliant',
  'Cabochon',
  'Carved',
  'Polished',
  'Rose',
  'Mixed',
  'Faceted',
  'Modified Brilliant',
  'Step',
  'Checkerboard',
  'Laser',
  'Scissor',
  'Bead',
  'Concave',
  'Barion',
  'Polished drilled',
  'Unpolished',
  'Engraved',
  'Natural drilled',
  'Natural',
];

const colorNames = [
  'Black',
  'White',
  'Gray',
  'Red',
  'Green',
  'Blue',
  'Pink',
  'Yellow',
  'Orange',
  'Purple',
  'Champagne',
  'Brown',
  'Teal',
  'Iridescent',
];

const quantityUnit = ['Carat', 'Gram'];

const initialState = {
  type: { id: 1, name: 'gemstone', title: 'Gemstone' },
  report: '',
  name: '',
  color: '',
  cut_name: '',
  cut_shape: '',
  cut_style: '',
  weight_amount: '',
  weight_unit: 'Carat',
  size_height: '',
  size_width: '',
  size_depth: '',
  quantity: 1,
  notes: '',
  isLabGrown: false,
};

function ItemQuickCreateGemstone({ onUpdatedEntity, setIsOpen, isPopupContext }) {
  const { user } = useAuth();
  const { setPageName } = useOutletContext();
  const { openSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [itemData, setItemData] = useState(initialState);
  const nameInputRef = useRef(null);

  useEffect(() => {
    setPageName('Item management');

    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const handleDataChange = (fieldName, data) => {
    setItemData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const formatEntity = (item) => {
    return {
      name: item.name,
      type: item.type,
      quantity: item.quantity,
      metadata: {
        custom_id: item.custom_id,
        report: item.report,
      },
      properties: {
        color: {
          name: item.color,
        },
        cut: {
          name: item.cut_name,
          shape: item.cut_shape,
          style: item.cut_style,
        },
        size: {
          height: Number(item.size_height),
          width: Number(item.size_width),
          depth: Number(item.size_depth),
        },
        weight: {
          amount: Number(item.weight_amount),
          unit: item.weight_unit.toLocaleLowerCase(),
        },
        notes: [
          {
            note: item.notes,
            user: { id: user.identity || '', metadata: user.payload },
            timestamp: new Date().getTime() + '',
          },
        ],
        gemological_composition: {
          is_lab_grown: item.isLabGrown,
        },
      },
    };
  };

  const handleSubmit = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }

      if (!itemData?.name) {
        openSnackbar('Please provide a name', 'error');
        return;
      }

      if (!itemData?.report) {
        openSnackbar('Please provide a report', 'error');
        return;
      }

      if (!itemData?.cut_name) {
        openSnackbar('Please provide a cut name', 'error');
        return;
      }

      if (!itemData?.weight_amount) {
        openSnackbar('Please provide a weight', 'error');
        return;
      }

      if (!itemData?.size_height) {
        openSnackbar('Please provide a height', 'error');
        return;
      }

      if (!itemData?.size_width) {
        openSnackbar('Please provide a width', 'error');
        return;
      }

      if (!itemData?.size_depth) {
        openSnackbar('Please provide a depth', 'error');
        return;
      }

      setIsLoading(true);

      const item = formatEntity(itemData);
      const sanitizedUser = { ...user.payload };
      delete sanitizedUser.roles;

      item.created = {
        user: {
          id: user.identity || '',
          metadata: sanitizedUser,
        },
        timestamp: new Date().getTime() + '',
      };

      const response = await createEntity(item, InventoryItemService);

      // Update parent states
      setIsLoading(false);
      onUpdatedEntity('create', response);

      if (setIsOpen) {
        setIsOpen(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      onUpdatedEntity('error', null);
      if (setIsOpen) {
        setIsOpen(false);
      }
    }
  };

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12' : ''}>
        <div className="card mb-0">
          <div className="card-body py-4">
            <section className="row">
              <article className="col-12">
                <h4 className="header-title">Create {itemData?.type?.title ?? 'item'}</h4>
                <p className="sub-header mb-1">
                  To get started, fill out some basic information about the item you're adding to
                  the inventory.
                </p>
              </article>
            </section>

            <section>
              <form onSubmit={handleSubmit}>
                <article className="row">
                  <header className="my-1">
                    <h5>General information</h5>
                  </header>
                  <section className="mb-3 col-12 col-md-4">
                    <Autocomplete
                      disablePortal
                      id="name-autocomplete"
                      options={gemstoneNames}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setItemData({
                          ...itemData,
                          name: newValue ? newValue.name : '',
                          color: newValue ? newValue.color : '',
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="w-100"
                          id="name-input"
                          label="Gemstone"
                          value={itemData.name}
                          placeholder=""
                          required
                          autoComplete="off"
                          inputRef={nameInputRef}
                        />
                      )}
                    />

                    <FormHelperText>Choose a name from list.</FormHelperText>
                  </section>
                  <section className="mb-3 col-12 col-md-4">
                    <Autocomplete
                      disablePortal
                      id="name-autocomplete"
                      options={colorNames}
                      value={itemData.color || ''}
                      onChange={(event, newValue) => {
                        handleDataChange('color', newValue || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="w-100"
                          id="color-input"
                          label="Color"
                          value={itemData.color}
                          placeholder=""
                          required
                          autoComplete="off"
                          onChange={(event) => handleDataChange('color', event?.target?.value)}
                        />
                      )}
                    />

                    <FormHelperText>Choose a color from list.</FormHelperText>
                  </section>
                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="cut-name-input"
                      label="Report"
                      value={itemData.report}
                      placeholder="0"
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('report', event.target.value);
                      }}
                    />
                    <FormHelperText>Number from bag</FormHelperText>
                  </section>
                </article>
                <article className="row">
                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="cut-name-input"
                      label="Quantity"
                      value={itemData.quantity}
                      placeholder="0"
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('quantity', event.target.value);
                      }}
                    />
                    <FormHelperText>How many gems in lot</FormHelperText>
                  </section>

                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="weight-input"
                      label="Lot weight"
                      value={itemData.weight_amount}
                      placeholder="0"
                      helperText=""
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('weight_amount', event.target.value);
                      }}
                    />
                    <FormHelperText>Total weight of all gems</FormHelperText>
                  </section>

                  <section className="col-12 col-md-4">
                    <FormControl className="w-100" required>
                      <InputLabel id="weight-unit-label">Weight Unit</InputLabel>
                      <Select
                        labelId="weight-unit-label"
                        id="weight-unit-select"
                        value={itemData.weight_unit || ''}
                        onChange={(event) => {
                          handleDataChange('weight_unit', event.target.value);
                        }}
                        label="Weight Unit"
                      >
                        {quantityUnit.map((unit) => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </section>
                </article>
                <article className="row">
                  <header className="my-1">
                    <h5>Cut</h5>
                  </header>
                  <section className="col-12 col-md-4">
                    <Autocomplete
                      disablePortal
                      id="cut-name-autocomplete"
                      options={gemstoneCutNames}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setItemData({
                          ...itemData,
                          cut_name: newValue ? newValue.name : '',
                          cut_shape: newValue ? newValue.shape : '',
                          cut_style: newValue ? newValue.style : '',
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="w-100"
                          type="text"
                          id="cut-name-input"
                          label="Name"
                          value={itemData.cut_name}
                          placeholder="Baguette"
                          required
                          autoComplete="off"
                        />
                      )}
                    />
                    <FormHelperText>Traditional cut name</FormHelperText>
                  </section>
                  <section className="col-12 col-md-4">
                    <Autocomplete
                      disablePortal
                      id="cut-shape-autocomplete"
                      options={gemstoneCutShapes}
                      value={itemData.cut_shape}
                      onChange={(event, newValue) => {
                        setItemData({
                          ...itemData,
                          cut_shape: newValue ? newValue : '',
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="w-100"
                          type="text"
                          id="cut-shape-input"
                          label="Shape"
                          value={itemData.cut_shape}
                          placeholder="Rectangle"
                          required
                          autoComplete="off"
                        />
                      )}
                    />
                    <FormHelperText>This is the external shape</FormHelperText>
                  </section>
                  <section className="col-12 col-md-4">
                    <Autocomplete
                      disablePortal
                      id="cut-style-input"
                      options={gemstoneCutStyles}
                      value={itemData.cut_style}
                      onChange={(event, newValue) => {
                        setItemData({
                          ...itemData,
                          cut_style: newValue ? newValue : '',
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="w-100"
                          id="cut-style-input"
                          label="Style"
                          value={itemData.cut_style}
                          placeholder="Step"
                          required
                          autoComplete="off"
                        />
                      )}
                    />
                    <FormHelperText>Pavilion style</FormHelperText>
                  </section>
                </article>
                <article className="row">
                  <header className="my-1">
                    <h5>Measures</h5>
                  </header>
                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="cut-name-input"
                      label="Height"
                      value={itemData.size_height}
                      placeholder="0"
                      helperText="Vertical size in mm"
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('size_height', event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <i className="mdi mdi-arrow-expand-vertical bg-body p-1 text-center w-35px rounded-circle"></i>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </section>
                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="cut-name-input"
                      label="Width"
                      value={itemData.size_width}
                      placeholder="0"
                      helperText="Horizontal size in mm"
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('size_width', event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <i className="mdi mdi-arrow-expand-horizontal bg-body p-1 text-center w-35px rounded-circle"></i>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </section>
                  <section className="col-12 col-md-4">
                    <TextField
                      className="w-100"
                      type="number"
                      id="cut-name-input"
                      label="Depth"
                      value={itemData.size_depth}
                      placeholder="0"
                      helperText="From top to down in mm"
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('size_depth', event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <i className="mdi mdi-axis-z-arrow bg-body p-1 text-center w-35px rounded-circle"></i>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </section>
                </article>
                <article className="row mb-3">
                  <header className="my-1">
                    <h5>Others</h5>
                  </header>
                  <section className="col-12">
                    <TextField
                      className="w-100"
                      id="notes-input"
                      label="Notes"
                      value={itemData.notes}
                      autoComplete="off"
                      onChange={(event) => {
                        handleDataChange('notes', event.target.value);
                      }}
                      multiline
                      maxRows={4}
                      rows={2}
                    />
                  </section>
                  <section className="col-12">
                    <FormControl component="fieldset" className="w-100">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={itemData.isLabGrown || false}
                            onChange={(event) =>
                              handleDataChange('isLabGrown', event.target.checked)
                            }
                          />
                        }
                        label="Lab Grown"
                      />
                      <FormHelperText>Indicates if the gemstone is laboratory grown</FormHelperText>
                    </FormControl>
                  </section>
                </article>

                <article className="row">
                  <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
                    {isLoading ? (
                      <button type="button" disabled className="btn btn-primary">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="ms-2">Saving</span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-success waves-effect waves-light"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    )}
                  </section>
                </article>
              </form>
            </section>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ItemQuickCreateGemstone;
